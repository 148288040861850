import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview">
                <a href="/hoe-werkt-imodium-instant/"> Hoe werkt IMODIUM®?</a>
              </li>
              <li className="last">
                <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                  De werkzame stof: loperamide
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-4">
            <h1>Hoe werkt IMODIUM® tegen de symptomen van diarree?</h1>
            <div className="intro row">
              <div className="three columns">
                <h2>Hoe ontstaat diarree?</h2>
              </div>
              <div className="nine columns">
                <p>
                  Heb je zo nu en dan eens diarree? Dit komt doordat je darm
                  minder vocht en voedingsstoffen opneemt dan normaal, en meer
                  water en mineralen vrijstelt. Dit fenomeen zorgt ervoor dat je
                  darm intensiever gaat werken en je darminhoud sneller wordt
                  voortgestuwd. Het resultaat is een waterige ontlasting.
                </p>
              </div>
            </div>
            <video autoPlay height="auto" muted width="100%">
              <source src="/assets/files/bowel.mp4" type="video/mp4" /> Your
              browser does not support the video tag.{" "}
            </video>
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />
            </p>
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />{" "}
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />{" "}
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />{" "}
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              <source src="/assets/files/bowel.mp4" type="video/mp4" />
            </p>
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <source src="/assets/files/bowel.mp4" type="video/mp4" />
            <p>
              Bekijk bovenstaande animatie en begrijp beter de werking van
              IMODIUM® tegen diarree op basis van de werkzame stof{" "}
              <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                loperamide
              </a>
              .
            </p>
            <h3>
              IMODIUM<sup>®</sup>&nbsp;bij diarree
            </h3>
            <p>
              Met de werkzame stof{" "}
              <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                loperamide
              </a>
              &nbsp;vermindert IMODIUM<sup>®</sup>&nbsp;de overmatige
              darmactiviteit, zonder de normale darmfuncties te beïnvloeden. De
              darminhoud blijft zo langer in contact met het darmslijmvlies,
              zodat er meer vloeistof uit de darm opgenomen kan worden.
            </p>
            <p>
              Op die manier kun je de symptomen van diarree behandelen. Snel en
              doelmatig wordt diarree overwonnen en je natuurlijke harmonie
              hersteld.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p />
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
